//------------------------------------------------------------------------
// Art Collection grab scroll
//-----------------------------------------------------------------------

export default class ArtCollectionScroll {
  constructor() {
    this.artCollections = document.querySelectorAll('.ArtCollection-rows');

    for (var i = 0; i < this.artCollections.length; ++i) {
        let collection = this.artCollections[i];
        let isDown = false;
        let startX;
        let scrollLeft;

        collection.addEventListener('mousedown', (e) => {
          isDown = true;
          collection.classList.add('active');
          startX = e.pageX - collection.offsetLeft;
          scrollLeft = collection.scrollLeft;
        });
        collection.addEventListener('mouseleave', () => {
          isDown = false;
          collection.classList.remove('active');
        });
        collection.addEventListener('mouseup', () => {
          isDown = false;
          collection.classList.remove('active');
        });
        collection.addEventListener('mousemove', (e) => {
          if(!isDown) return;
          e.preventDefault();
          const x = e.pageX - collection.offsetLeft;
          const walk = (x - startX);
          collection.scrollLeft = scrollLeft - walk;
        });
    }
  }
}

// Init
document.querySelectorAll('.ArtCollection-rows').forEach((el) => {
  new ArtCollectionScroll(el);
});

import React from "react";
import ReactDOM from "react-dom";
import Search from "./components/search/Search";

const navSearch = document.querySelector(".Nav-search");
if (navSearch) {
  // conditional added to handle eslint error: ReactDOM.render is deprecated since React 18.0.0, use createRoot instead
  if (ReactDOM.createRoot) {
    const root = ReactDOM.createRoot(navSearch);
    root.render(<Search />);
  } else {
    ReactDOM.render(<Search />, navSearch);
  }
}

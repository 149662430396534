//------------------------------------------------------------------------
// Art Collection transition animations
//-----------------------------------------------------------------------

export default class ArtCollection {
  constructor() {
    this.toggles = document.querySelectorAll('.ArtAndArtist-tab-toggle');

    for (var i = 0; i < this.toggles.length; ++i) {
      this.toggles[i].addEventListener('click', evt => {
        var tabId = evt.target.getAttribute('aria-controls');
        var tabPanel = document.getElementById(tabId);
        var tabImages = tabPanel.querySelectorAll('.ArtCollection-image');

        // Fade images in sequentially
        var i = 1;
        tabImages.forEach((el) => {
          el.style.opacity = 0;
          el.style.transition = 'opacity 400ms ease-in-out';
          var imageEl = el;
          setTimeout(() => {
            imageEl.style.opacity = 1;
          }, 80*i)
          i++;
        })
      })
    }
  }
}

// Init
document.querySelectorAll('.ArtAndArtist').forEach((el) => {
  new ArtCollection(el);
});

//------------------------------------------------------------------------
// Art Collection transition animations
//-----------------------------------------------------------------------

export default class ScrollToAnimate {
  constructor(el) {

    // Check to make sure browser supports IntersectionObserver
    if (!('IntersectionObserver' in window) ||
      !('IntersectionObserverEntry' in window) ||
      !('intersectionRatio' in window.IntersectionObserverEntry.prototype)) {
        el.classList.add('in-viewport');
    } else {
      // Setup scroll observer to check if element is visible
      var observer = new IntersectionObserver(function(entries) {
        if(entries[0].isIntersecting === true) {
          el.classList.add('in-viewport');
        }
      }, { threshold: [0] });

      observer.observe(el);
    }
  }
}

// Init
document.querySelectorAll('.a-scrollToAnimate').forEach((el) => {
  new ScrollToAnimate(el);
});

/**
* Hook to check if an element is visible
* - used for lazy loading
*/

import { useState, useEffect } from "react";

export default function useOnScreen(ref) {

  const [isIntersecting, setIntersecting] = useState(false);

  // Check to make sure browser supports IntersectionObserver
  if (!('IntersectionObserver' in window) ||
    !('IntersectionObserverEntry' in window) ||
    !('intersectionRatio' in window.IntersectionObserverEntry.prototype)) {

    setIntersecting(true);
  } else {
    const observer = new IntersectionObserver(
      ([entry]) => setIntersecting(entry.isIntersecting)
    )

    useEffect(() => {
      observer.observe(ref.current)

      // Remove the observer as soon as the component is unmounted
      return () => { observer.disconnect() }
    }, [])
  }

  return isIntersecting
}
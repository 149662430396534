//------------------------------------------------------------------------
// Subnav expandable menus
//------------------------------------------------------------------------
import ExpandToggle from '@threespot/expand-toggle';

export default class expandableToggles {
  constructor() {
    this.toggles = document.querySelectorAll('[data-expands]:not([data-accordion])');
    this.expandables = [];// placeholder
    this.allowMultipleOpen = true;

    // Main nav breakpoint
    this.mediaQueryList = window.matchMedia('(min-width: 1200px)');

    // Init toggles
    this.toggles.forEach(el => {
      let toggle = new ExpandToggle(el);
      this.expandables.push(toggle);

      // Collapse toggle when switching between mobile and desktop nav layout
      document.documentElement.addEventListener('nav-reset', function () {
        toggle.collapse();
      });

      // Update height when web fonts have loaded
      document.documentElement.addEventListener('fonts-loaded', function () {
        toggle.updateExpandedHeight();
      });
    });

    // Close toggles if click outside of them
    window.addEventListener('click', evt => {
      if (!this.allowMultipleOpen &&
        !evt.target.closest('.Nav-toggle')) {
        this.closeMenus();
      }
    });

    // Logic to check if only one menu should be open at a time
    this.expandables.forEach((currToggle, currindex) => {
      // Add “expand” event listener to toggles
      // (has to be done after “this.expandables” array has been populated)
      currToggle.on('expand', () => {
        if (!this.allowMultipleOpen) {
          // Close other menus
          this.expandables.forEach((toggle, index) => {
            if (index !== currindex) {
              toggle.collapse();
            }
          });
        }
      });
    });
  }

  closeMenus() {
    this.expandables.forEach(toggle => {
      toggle.collapse();
    });
  }
}

// Init
new expandableToggles();
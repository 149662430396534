import React, { useState, useEffect } from "react";
import useDebounce from "../../hooks/useDebounce";
import Icon from "../Icon";

const CollectionResults = ({ query, setHasResults }) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState([]);
  const debouncedQuery = useDebounce(query, 200);

  useEffect(() => {
    setIsLoaded(false);
  }, [query]);

  useEffect(() => {
    if (debouncedQuery) {
      fetch(
        `https://collections-search-5206855655.us-west-2.bonsaisearch.net/gokm/_search?q=${encodeURIComponent(
          query
        )}`
      )
        .then((res) => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            if (result.hits.hits.length > 0 ) {
              setItems(result.hits.hits);
              setTotal(result.hits.total);
              setHasResults(true);
            }
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        );
    } else {
      setItems([]);
      setHasResults(false);
    }
  }, [debouncedQuery]);

  const Result = ({ item }) => {
    let imgId = null;

    // Get image id from representation URL
    if (item._source.representation && item._source.representation.views && item._source.representation.views[0]) {
      imgId = item._source.representation.views[0].id.split("/").pop();
    }

    const imgSrc = (item._source.representation && item._source.representation.views && item._source.representation.views[0])
      ? `https://iiif.okeeffemuseum.org/image/iiif/2/${imgId}/full/,600/0/default.jpg`
      : "https://collections.okeeffemuseum.org/assets/no-image.png";

    return (
      <div
        style={{
          marginTop: "20px",
          marginRight: "40px",
          flexShrink: 0,
        }}
      >
        <a
          className="SearchResults-CollectionsItem c-link-black"
          href={`https://collections.okeeffemuseum.org/${item._id}/`}
          style={{ display: "table" }}
        >
          <img
            className="SearchResults-CollectionsItem-image"
            src={imgSrc}
            aria-hidden="true"
            role="presentation"
          />
          <br />
          <span
            style={{
              display: "table-caption",
              captionSide: "bottom",
              paddingTop: "12px",
            }}
          >
            {item._source.label}
          </span>
        </a>
      </div>
    );
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (isLoaded && query) {
    return (
      <>
        <div
          className="SearchResults-header"
        >
          <h2 className="f-h2">From the Collection</h2>

          {items.length > 0 && (
            <a
              href={`https://collections.okeeffemuseum.org/search/#query_string=${query}`}
              className="c-link-arrow-black"
            >
              See {total} item{parseInt(total) > 1 ? "s" : null} in the
              Collection
              <Icon name="rightArrow" />
            </a>
          )}

          {items.length === 0 && (
            <a
              href={`https://collections.okeeffemuseum.org`}
              className="c-link-arrow-black"
            >
              Go to Collection
              <Icon name="rightArrow" />
            </a>
          )}
        </div>
        {items.length > 0 && (
          <div className="l-fullwidth--right">
            <div
              style={{
                display: "flex",
                overflow: "scroll",
              }}
            >
              {/* Filter out items with no image, limit to 6 */}
              {items
                .filter((item) => item._source.representation)
                .slice(0, 6)
                .map((item, i) => (
                  <Result key={i} item={item} />
                ))}
            </div>
          </div>
        )}

        {items.length === 0 && (
          <div
            className="u-center"
            style={{ paddingTop: "200px", paddingBottom: "200px" }}
          >
            <p className="f-h3">
              No results in collections
              <br /> for "{query}"
            </p>
          </div>
        )}
      </>
    );
  } else {
    return null;
  }
};

export default CollectionResults;

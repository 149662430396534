//------------------------------------------------------------------------
// Accordion
//------------------------------------------------------------------------
import ExpandToggle from '@threespot/expand-toggle';

if (document.querySelector('[data-accordion]')) {
  const accordionDrawers = document.querySelectorAll('[data-accordion]');

  accordionDrawers.forEach(accordionDrawer => {
    console.log('accordion drawer');
    new ExpandToggle(accordionDrawer);
  });
}
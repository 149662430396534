import React, { useEffect, useState } from "react";
import useDebounce from "../../hooks/useDebounce";
import Icon from "../Icon";

const WebResults = ({ query, setHasResults }) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const debouncedQuery = useDebounce(query, 200);

  useEffect(() => {
    setIsLoaded(false);
  }, [query]);

  useEffect(() => {
    if (debouncedQuery) {
      fetch(`/wp-json/wp/v2/search?search=${encodeURIComponent(query)}&_embed`)
        .then((res) => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            setItems(result);
            setHasResults(true);
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        );
    } else {
      setItems([]);
      setHasResults(false);
    }
  }, [debouncedQuery]);

  const Result = ({ item }) => {
    const imageUrl = item._embedded.self[0].featured_image_src;
    const postLabels = item._embedded.self[0].custom_post_labels;

    const getDate = () => {
      const postTypes = ["post", "news"];
      if (postTypes.includes(item.subtype)) {
        const options = {
          year: "numeric",
          month: "short",
          day: "numeric",
        };
        const date = new Date(item._embedded.self[0].date);
        return date.toLocaleDateString("en-us", options);
      } else {
        return false;
      }
    };

    const date = getDate();

    return (
      <li className="Listing-item">
        <article className="Listing-item-wrap">
          {imageUrl && (
            <a href={item.link} tabIndex="-1" className="Listing-thumbnail">
              <img
                className="Listing-thumbnail-image"
                src={imageUrl}
                aria-hidden="true"
                role="presentation"
              />
            </a>
          )}
          <div className="Listing-text">
            {postLabels && postLabels.length && (
              <p className="Listing-labels">
                {postLabels.map((label, key) => (
                  <span key={key} className="Listing-labels-text f-eyebrow">
                    {label}
                  </span>
                ))}
              </p>
            )}
            <p className="Listing-title">
              <a
                href={item.url}
                className="Listing-title-link"
                // render escaped post titles
                dangerouslySetInnerHTML={{ __html: item.title }}
              />
            </p>
            {date && (
              <div className="Listing-meta">
                <time className="Listing-meta-date">{date}</time>
              </div>
            )}
          </div>
        </article>

        <a
          href={item.url}
          tabIndex="-1"
          className="Listing-link"
          aria-hidden="true"
          focusable="false"
        ></a>
      </li>
    );
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (isLoaded && query) {
    return (
      <>
        <div
          className="SearchResults-header"
        >
          <h2 className="f-h2 mt-3">Website Results</h2>
          <a href={`/?s=${query}`} className="c-link-arrow-black">
            {items.length ? "View and filter Results" : "Advanced Search"}
            <Icon name="rightArrow" />
          </a>
        </div>
        <div className="SearchResults-wrap l-wrap--narrow">
          <div className="Listing">
            <ul className="Listing-list">
              {items.map((item) => (
                <Result key={item.id} item={item} />
              ))}
            </ul>

            {items.length === 0 && (
              <div
                className="u-center"
                style={{ paddingTop: "200px", paddingBottom: "200px" }}
              >
                <p className="f-h3">
                  No website results for
                  <br /> for "{query}"
                </p>
              </div>
            )}

            <div className="u-center">
              <a href={`/?s=${query}`} className="c-link-arrow-black">
                View More and filter Results
                <Icon name="rightArrow" />
              </a>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
};

export default WebResults;

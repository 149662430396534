//------------------------------------------------------------------------
// Site Wide Banner dismiss behavior
//-----------------------------------------------------------------------
import Cookies from '../../../../node_modules/js-cookie/dist/js.cookie.mjs'

export default class SiteWideBanner {
  constructor(el) {
    this.el = el;
    this.dismiss = el.querySelector(".SiteWideBanner-dismiss");

    if (Cookies.get('banner') && Cookies.get('banner') != "dismissed") {
      $(this.el).slideDown();
    }

    this.dismiss.addEventListener('click', function (evt) {
      const parentBanner = evt.target.closest('.SiteWideBanner');
      $(parentBanner).slideUp();
      Cookies.set('banner', "dismissed", { expires: 1 })
    });
  }
}

// Init
document.querySelectorAll('.SiteWideBanner').forEach((el) => {
  new SiteWideBanner(el);
});

//------------------------------------------------------------------------
// Membership discount form on Shop cart
//-----------------------------------------------------------------------

export default class MembershipDiscountForm {
  constructor() {
    if (typeof woocommerce_params === 'undefined') {
        return false;
    }

    this.submitButton = document.querySelector("#membership_email-wrapper button");

    if (!this.submitButton) {
      return false;
    }

    this.submitButton.addEventListener('click', function() {
      this.emailInput = document.querySelector("#membership_email-wrapper input")
      const value = this.emailInput.value;

      // FIXME - integrate with ALTRU to check if email address is associated with an active membership
      $.ajax({
        type: 'POST',
        url: 'fixmeAltuEndpoint',
        data: {
          'action': 'membership_email',
          'membership_email': value,
        },
        success: function(response) {// eslint-disable-line no-unused-vars
          // console.log(response);

          if (value == "email") {
            $(document.body).trigger("added_to_cart"); // refresh cart
          } else {
            console.warn("email not valid");
          }
        },
        error: function(error) {
          console.warn("something went wrong", error);
        }
      });
    });
  }
}

// Init
document.querySelectorAll('.woocommerce-cart-form').forEach((el) => {
  new MembershipDiscountForm(el);
});

import React, { useEffect, useState } from "react";
import OpenSeaDragon from "openseadragon";

const ImageViewer = ({imageViews}) => {
  const [viewer, setViewer] = useState();

  useEffect(() => {
    if (viewer) {
      const tileSources = imageViews;

      viewer.open(tileSources);

    }
    // we only care about the object prop, ignore other dependencies
    // eslint-disable-next-line
  }, [viewer]);

  useEffect(() => {
    initOpenSeaDragon();

    return () => {
      // clean up by destroying this viewer when unmounting
      viewer && viewer.destroy();
    };
    // we only want to run this once, dont worry about other dependencies
    // eslint-disable-next-line
  }, []);

  const initOpenSeaDragon = () => {
    viewer && viewer.destroy();

    const newViewer = OpenSeaDragon({
      id: "viewer",
      sequenceMode: true,
      zoomInButton:   "zoom-in",
      zoomOutButton:  "zoom-out",
      previousButton: "previous",
      nextButton: "next",
      homeButton: "home",
      fullPageButton: "full-page",
    });

    setViewer(newViewer);
  };

  return (
    <div className="Lightbox-viewer" id="viewer" style={{ height: "70vh", width: "100%" }}></div>
  )
};

export default ImageViewer;
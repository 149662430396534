import React from "react";

const Icon = ({ name }) => {
  switch (name) {
    case "search":
      return (
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.375 21.125C16.7598 21.125 21.125 16.7598 21.125 11.375C21.125 5.99022 16.7598 1.625 11.375 1.625C5.99022 1.625 1.625 5.99022 1.625 11.375C1.625 16.7598 5.99022 21.125 11.375 21.125Z"
            stroke="#000"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.6875 18.6875L24.375 24.375"
            stroke="#000"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case "rightArrow":
      return (
        <svg
          width="16"
          height="9"
          viewBox="0 0 16 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 4.5C0 4.36741 0.0602 4.24025 0.167357 4.1465C0.274514 4.05274 0.41985 4.00007 0.571392 4.00007H14.0482L10.4519 0.854512C10.3446 0.760638 10.2843 0.633318 10.2843 0.500561C10.2843 0.367804 10.3446 0.240484 10.4519 0.146611C10.5592 0.0527374 10.7047 3.12787e-09 10.8565 0C11.0082 -3.12787e-09 11.1537 0.0527375 11.261 0.146611L15.8321 4.14605C15.8853 4.19249 15.9276 4.24766 15.9564 4.30839C15.9852 4.36913 16 4.43424 16 4.5C16 4.56576 15.9852 4.63087 15.9564 4.69161C15.9276 4.75234 15.8853 4.80751 15.8321 4.85395L11.261 8.85339C11.1537 8.94726 11.0082 9 10.8565 9C10.7047 9 10.5592 8.94726 10.4519 8.85339C10.3446 8.75952 10.2843 8.6322 10.2843 8.49944C10.2843 8.36668 10.3446 8.23936 10.4519 8.14549L14.0482 4.99993H0.571392C0.41985 4.99993 0.274514 4.94726 0.167357 4.8535C0.0602 4.75975 0 4.63259 0 4.5Z"
            fill="white"
          />
        </svg>
      );
  }
};

export default Icon;

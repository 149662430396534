import React, { useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import scroll from "@threespot/freeze-scroll";

import ImageViewer from "./ImageViewer";
import useOnScreen from "../../hooks/useOnScreen";

const lightbox = document.querySelector(".Lightbox");
if (lightbox) {
  Modal.setAppElement(".Lightbox");
}

const Lightbox = ({ imageId }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [imageObect, setImageObject] = useState({});
  const [imageObectLoaded, setImageObjectLoaded] = useState(false);
  const [imageCollectionURL, setImageCollectionURL] = useState('');
  const [imageDimensions, setImageDimensions] = useState('');
  const [imageCredit, setImageCredit] = useState('');
  const [imageViews, setImageViews] = useState([]);
  const [imageIiifID, setImageIiifID] = useState('');
  const [imageYear, setImageYear] = useState('');
  const [imagePrintURL, setImagePrintURL] = useState('');

  // Check if element is visible for lazy loading Collections data
  const ref = useRef()
  const isVisible = useOnScreen(ref)

  useEffect(() => {
    if (modalOpen) {
      scroll.freeze();
    } else {
      scroll.unfreeze();
    }
  }, [modalOpen]);

  const customStyles = {
    overlay: {
      zIndex: 1000,
      backgroundColor: "transparent",
    },
    content: {
      backdropFilter: "blur(10px)",
      background: "#f5f2ed",
      bottom: 0,
      border: 0,
      left: 0,
      inset: 0,
      padding: 0,
      right: 0,
      top: 0,
    },
  };

  const openLighbox = (event) => {
    const mouseUp = event.clientX;
    if (
      // need to distinguish drag events vs click events for the art collection grab scroll component
      event.target.className == "Lightbox-toggle" &&
      mouseUp < window.checkForDrag + 6 &&
      mouseUp > window.checkForDrag - 6
    ) {
      setModalOpen(!modalOpen);
    } else if (
      event.target.className != "Lightbox-toggle"
    ) {
      setModalOpen(!modalOpen);
    }
  };

  // Lazy load Collections Online Data
  if (isVisible) {
    const url = `https://collections-search-5206855655.us-west-2.bonsaisearch.net/gokm/_search`;
    const headers = new Headers();
    const body = {
        'query': {
          'ids': {
            'values':
              `${imageId}`
            }
          }
        }

    headers.append('Content-Type', 'application/json');
    // Basic Auth not needed on prod endpoint
    // headers.set('Authorization', 'Basic ' + btoa(username + ":" + password));

    fetch(url, {method:'POST',
      headers: headers,
      body: JSON.stringify(body)
      })
    .then((res) => res.json())
    .then(
      (result) => {
        const object = result.hits.hits[0]._source;

        setImageObject(object);
        setImageCollectionURL(object.site_link.collection);
        setImagePrintURL(object.site_link.print);
        setImageDimensions(object.caption.medium_dimension);
        setImageCredit(`${object.caption.owner}, ${object.caption.credit}, ${object.accession}`);

        const imageViewArray = []
        for (var i = 0; i < object.representation.views.length; i++) {
          imageViewArray.push(object.representation.views[i].id);
        }
        setImageViews(imageViewArray);
        setImageIiifID(imageViewArray[0].split("/").pop());

        const beginYear = object.timespan.begin_of_the_begin;
        const endYear = object.timespan.end_of_the_end;
        if (beginYear == endYear) {
          setImageYear(beginYear);
        } else {
          setImageYear(`${beginYear}-${endYear}`);
        }

        if(imageObect.length != {}){
          setImageObjectLoaded(true);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const Toggle = () => {
    const mouseDownCoords = e => {
      window.checkForDrag = e.clientX;
    };

    return (
      <img
        className="Lightbox-toggle"
        onMouseDown={(event) => mouseDownCoords(event)}
        onMouseUp={(event) => openLighbox(event)}
        ref={ref}
        src={imageObectLoaded ? `https://iiif.okeeffemuseum.org/image/iiif/2/${imageIiifID}/full/,600/0/default.jpg` : ''}
        alt={imageObectLoaded ? imageObect['label'] + imageObect['note'] : ''}
        aria-label="Open Deep Zoom Viewer"
        role="button"
      />
    );
  };

  const Close = () => {
    return (
      <button
        className="Lightbox-close"
        onClick={(event) => openLighbox(event)}
      >
        <span className="u-screenreader">Close Deep Zoom Viewer</span>
      </button>
    );
  };

  return (
    <>
      <Toggle />
      <Modal
        isOpen={modalOpen}
        style={customStyles}
        closeTimeoutMS={300} // timeout must equal CSS animation duration
      >
        <div className="Lightbox-modal">
          <div className="Lightbox-viewer-wrap">
            <button className="Lightbox-zoomIn" id="zoom-in">
              <span className="u-screenreader">Zoom In</span>
            </button>
            <button className="Lightbox-zoomOut" id="zoom-out">
              <span className="u-screenreader">Zoom Out</span>
            </button>
            <button className="Lightbox-fullpage" id="full-page">
              <span className="u-screenreader">Fullscreen viewer</span>
            </button>
            <button className="Lightbox-home" id="home">
              <span className="u-screenreader">Revert Image</span>
            </button>
            {imageViews.length > 1  &&
              <button className="Lightbox-prev" id="previous">
                <span className="u-screenreader">Previous Image</span>
              </button>
            }
            <ImageViewer imageViews={imageViews} />
            {imageViews.length > 1  &&
              <button className="Lightbox-next" id="next">
                <span className="u-screenreader">Next Image</span>
              </button>
            }
            <Close />
          </div>
          <div className="Lightbox-details bg-slate c-white l-padding">
            <div className="l-wrap--narrow">
              <div className="Lightbox-details-wrap">
                <div className="Lightbox-column">
                  <p className="Lightbox-heading">
                    {imageObect['label']}, {imageYear}
                  </p>
                  <p className="Lightbox-text">
                    {imageObect['note']}
                  </p>
                  <p className="Lightbox-text">
                    Artist: {imageObect['artist_label']}
                  </p>
                </div>
                <div className="Lightbox-column">
                  <a href={imageCollectionURL} className="Lightbox-link">
                    View in Collections
                  </a>
                  <a href={imagePrintURL} className="Lightbox-link">
                    Order Print
                  </a>
                  <p className="Lightbox-text">{imageDimensions}</p>
                  <p className="mb-2">
                    © {imageCredit}
                  </p>
                  <a href="http://rightsstatements.org/page/InC/1.0/?language=en" className="Lightbox-link">
                    View rights statement
                  </a>
                  <a href="https://arsny.com/licensing-requests/" className="Lightbox-link">
                    License this image
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Lightbox;

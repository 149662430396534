// Detect input method in order to hide outlines in an accessible manner
// https://github.com/ten1seven/what-input
// NOTE: Can drop once Safari supports :focus-visible to save 1.7kB (gzip)
//       https://caniuse.com/css-focus-visible
import "what-input";

// Emit “font-loaded” event so expandable components know to recalc their height
document.fonts.ready.then(function () {
  let fontEvent = new CustomEvent("fonts-loaded");
  document.documentElement.dispatchEvent(fontEvent);
});

import "./modules/accordion";
import "./modules/art-collection";
import "./modules/nav";
import "./modules/link-icons";
import "./modules/main-nav-submenus";
import "./modules/tabs";
import "./modules/search";
import "./modules/image-viewer";
import "./modules/scroll-to-animate";
import "./modules/art-collection-scroll";
import "./modules/collections-data";
import "./modules/membership-discount-form";
import "./modules/site-wide-banner";

import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import scroll from "@threespot/freeze-scroll";

import Header from "./Header";
import Icon from "../Icon";
import WebResults from "./WebResults";
import CollectionResults from "./CollectionResults";


const globalNav = document.querySelector(".Nav-search");
if (globalNav) {
  Modal.setAppElement(".Nav-search");
}

const Search = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [hasResults, setHasResults] = useState(false);
  const [query, setQuery] = useState("");

  const customStyles = {
    overlay: {
      zIndex: 1000,
      backgroundColor: "transparent",
    },
    content: {
      border: 0,
      inset: 0,
      padding: 0,
      background: hasResults ? "#f5f2ed" : "rgba(245, 242, 237, 0.9)",
      backdropFilter: "blur(10px)",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0
    },
  };

  useEffect(() => {
    if (modalOpen) {
      scroll.freeze();
    } else {
      scroll.unfreeze();
      // setQuery("");
    }
  }, [modalOpen]);

  const Toggle = () => {
    return (
      <button
        className="Nav-search-submit btn"
        onClick={() => setModalOpen(!modalOpen)}
      >
        <Icon name="search" />
        <span className="u-screenreader">Search</span>
      </button>
    );
  };

  return (
    <>
      <Toggle />
      <Modal
        isOpen={modalOpen}
        style={customStyles}
        closeTimeoutMS={300} // timeout must equal CSS animation duration
      >
        <Header setModalOpen={setModalOpen} query={query} setQuery={setQuery} />
        <div className="l-padding">
          <div className="l-wrap" style={{ paddingBottom: "200px" }}>
            <CollectionResults query={query} setHasResults={setHasResults} />
            <WebResults query={query} setHasResults={setHasResults} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Search;

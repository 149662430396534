//------------------------------------------------------------------------
// Populate Collections data from an image ID
//-----------------------------------------------------------------------

export default class CollectionsData {
  constructor(el) {
    const imageId = el.dataset.imageId;
    const url = `https://collections-search-5206855655.us-west-2.bonsaisearch.net/gokm/_search`;
    const headers = new Headers();
    const body = {
        'query': {
          'ids': {
            'values':
              `${imageId}`
            }
          }
        }

    headers.append('Content-Type', 'application/json');

    // Basic Auth not needed on prod endpoint
    // headers.set('Authorization', 'Basic ' + btoa(username + ":" + password));

    // Check to make sure browser supports IntersectionObserver
    if (!('IntersectionObserver' in window) ||
      !('IntersectionObserverEntry' in window) ||
      !('intersectionRatio' in window.IntersectionObserverEntry.prototype)) {
      fetchImageData();
    } else {
      // Setup scroll observer to check if element is visible
      var observer = new IntersectionObserver(function(images) {
        if(images[0].isIntersecting === true) {
          fetchImageData();
        }
      }, { threshold: [0] });

      observer.observe(el); // watch for elements that need to fetch data
    }

    // Fetch data from the Collections Online to populate the element
    function fetchImageData() {
      fetch(url, {method:'POST',
        headers: headers,
        body: JSON.stringify(body)
        })
      .then((res) => res.json())
      .then(
        (result) => {
          const object = result.hits.hits[0]._source;
          const imgEl = el.querySelector('.CollectionsData-img');
          const titleEl = el.querySelector('.CollectionsData-title');
          const artistEl = el.querySelector('.CollectionsData-artist');
          const dateEl = el.querySelector('.CollectionsData-date');
          const artistDateEl = el.querySelector('.CollectionsData-artistDate');

          if (imgEl) {
            imgEl.src = `https://iiif.okeeffemuseum.org/image/iiif/2/${object.representation.views[0].id.split("/").pop()}/full/,600/0/default.jpg`;
            imgEl.alt = `"${object['note'][0]}`;
          }

          if (titleEl) {
            titleEl.innerText = object['label'][0];
          }

          if (artistEl) {
            artistEl.innerText = object['artist_label'];
          }

          if (dateEl) {
            const beginYear = object.timespan.begin_of_the_begin;
            const endYear = object.timespan.end_of_the_end;
            if (beginYear == endYear) {
              dateEl.innerText = beginYear;
            } else {
              dateEl.innerText = `${beginYear}-${endYear}`;
            }
          }

          if (artistDateEl) {
            const beginYear = object.timespan.begin_of_the_begin;
            const endYear = object.timespan.end_of_the_end;
            if (beginYear == endYear) {
              artistDateEl.innerText = `${object['artist_label']}, ${beginYear}`;
            } else {
              artistDateEl.innerText = `${object['artist_label']}, ${beginYear}-${endYear}`;
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }
}

// Init
document.querySelectorAll('.CollectionsData').forEach((el) => {
  new CollectionsData(el);
});

import React from "react";
import ReactDOM from "react-dom";
import Lightbox from "./components/imageViewer/Lightbox";

document.querySelectorAll('.Lightbox').forEach((el) => {
  const props = el.dataset;

  // conditional added to handle eslint error: ReactDOM.render is deprecated since React 18.0.0, use createRoot instead
  if (ReactDOM.createRoot) {
    const root = ReactDOM.createRoot(el);
    root.render(<Lightbox {...props} />);
  } else {
    ReactDOM.render(<Lightbox {...props} />, el);
  }
});

//------------------------------------------------------------------------
// Main Navigation
//
// Uses hover to open submenus for mouse users,
// and uses button toggles for keyboard users.
// ------------------------------------------------------------------------

export default class mainNavSubmenus {
  constructor() {
    this.navItems = document.querySelectorAll('.Nav-primary-item');
    this.parentNavToggles = document.querySelectorAll('.Nav-toggle.level-0');
    this.shopItem = document.querySelectorAll('.Nav-shop');
    this.shopToggle = document.querySelectorAll('.Nav-shop-toggle');

    // Hover state for all top level nav items
    // And open submenus on hover for mouse users
    if (window.innerWidth > 1200) {
      this.navItems.forEach(el => {
        el.addEventListener('mouseover', function(){
          el.classList.add('active');
          if (el.classList.contains('has-submenu')) {
            el.classList.add('open');
          }
        });
        el.addEventListener('mouseout', function() {
          const activeItem = document.querySelector('.Nav-primary-item.active');
          if (activeItem) {
            activeItem.classList.remove('active');
          }
          if (el.classList.contains('has-submenu')) {
            el.classList.remove('open');
          }
        });
      });
    }

    // Listen for breakpoint change, update behavior
    window.addEventListener('resize', () => {
      if (window.innerWidth > 1200) {
        this.navItems.forEach(el => {
          el.addEventListener('mouseover', function(){
            el.classList.add('active');
            if (el.classList.contains('has-submenu')) {
              el.classList.add('open');
            }
          });
          el.addEventListener('mouseout', function() {
            const activeItem = document.querySelector('.Nav-primary-item.active');
            if (activeItem) {
              activeItem.classList.remove('active');
            }
            if (el.classList.contains('has-submenu')) {
              el.classList.remove('open');
            }
          });
        });
      }
    });
    // Open submenus with toggle for keyboard users
    this.parentNavToggles.forEach(el => {
      const parent = el.closest('.Nav-primary-item.has-submenu');
      el.addEventListener('click', function(event){
        if (parent.classList.contains('open')) {
          parent.classList.remove('open', 'active');
          event.target.ariaPressed = "false";
        } else {
          // Close other open dropdown before opening a new one
          if (document.querySelectorAll('.Nav-primary-item.has-submenu.active').length > 0){
            document.querySelectorAll('.Nav-primary-item.has-submenu.active')[0].ariaPressed = "false";
            document.querySelectorAll('.Nav-primary-item.has-submenu.active')[0].classList.remove('open', 'active');
          }
          parent.classList.add('open', 'active');
          event.target.ariaPressed = "true";
        }
      });
    });

    // Open Shop submenu on hover for mouse users
    this.shopItem.forEach(el => {
      el.addEventListener('mouseover', function(){
        this.classList.add('open');
      });
      el.addEventListener('mouseout', function(){
        this.classList.remove('open');
      });
    });

    // Open Shop submenus with toggle for keyboard users
    this.shopToggle.forEach(el => {
      const parent = el.closest('.Nav-shop');
      el.addEventListener('keyup', function(event){
        if (event.keyCode === 13) {
          if (parent.classList.contains('open')) {
            parent.classList.remove('open');
            event.target.ariaPressed = "false";
          } else {
            parent.classList.add('open');
            event.target.ariaPressed = "true";
          }
        }
      });
    });
  }
}

// Init
new mainNavSubmenus();
